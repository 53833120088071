<template>
<div>
    <CRow v-if="automation || edit">
        <CCol class="col mt-0 pt-0">
            <label class="strong label-col mt-2 mr-0">Enable Webhook</label>  
            <CSwitch class="pt-1" size="sm" color="success" variant="3d" 
                    :checked.sync="enabled"
                    :disabled="disabled || !edit"
            /> 
        </CCol>
    </CRow>
    <CRow v-if="automation || edit">
        <CCol sm="6" class="d-flex">
            <label class="strong label-col mt-2 mr-0">WebHook URL:</label>   
            <span  v-if="!edit" class="col form-control text-info ml-0 mt-0">{{ webhookUrl }}</span>
            <CInput v-if="edit" v-model="webhookUrl" style="width: 100%;" 
                    class="mr-auto mb-0 pb-0" 
                    addInputClasses="text-primary pl-2 strong"
                    :isValid="isUrlValidOrEmpty"
                    placeholder="https://..."
                    :disabled="disabled"
            />
        </CCol>
        <CCol sm="2" style="min-width: 250px;" class="d-flex">
            <label class="strong mr-3 mt-2" >HTTP Method:</label>   
            <span v-if="!edit" class="col form-control text-info mr-auto"  style="max-width: 80px;">
                {{ httpMethod }}
            </span>
            <CSelect v-if="edit"
                    :options='httpOptions'
                     addInputClasses="select-http-method text-primary strong" 
                    :value.sync="httpMethod"
                    :disabled="disabled"
                     class="mb-0 pb-0 pr-0 mr-0 ml-0 pl-0 "
                     style="max-width: 100px; display: inline;"
            />
        </CCol>
        <CCol class="d-flex col">
            <label class="strong mt-2 mr-3">Authorization:</label>   
            <span v-if="!edit" class="col form-control text-info mr-auto">
                {{ authHeader }}
            </span>
            <CInput v-if="edit" 
                    v-model="authHeader"
                   :disabled="disabled"
                    class="mb-0 pb-0 flex-grow-1" 
                    addInputClasses="text-primary pl-2 strong"                    
                    placeholder="(optional)"
            />
        </CCol>
    </CRow>
    <CAlert v-else color="info" class="mt-2 mb-0" :fade="false">Automation is not yet configured</CAlert>
    <CAlert v-if="errorMessage" color="danger" class="mt-0 mb-0 mt-2" :fade="false"><strong>Could not save automation settings</strong><br /><p style="white-space: pre-line" class="pb-0 mb-0">{{ errorMessage}}</p></CAlert>
</div>
</template>
<script>
import { validateUrl } from '@/utils';
import { mapActions } from 'vuex';
import { actionNames } from '@/store/modules/general/actions';
import { getResponseData } from '@/utils';

export default {
    props: {
        automation: Object,
        edit: Boolean,
        disabled: Boolean
    },
    emits: ['saved'],
    data() {
        return {
            errorMessage: null,
            enabled: false,
            webhookUrl: null,
            httpMethod: null,
            authHeader: null,
            httpOptions: ['POST', 'PUT']
        }
    },
    methods: {
         ...mapActions({
            update: actionNames.UPDATE
        }),
        getData() {
            const automation = {
                enabled: this.enabled,
                webhookUrl: this.webhookUrl,
                httpMethod: this.httpMethod,
                authHeader: this.authHeader
            };
            return automation;
        },
        setData(obj) {
            if (obj) {
                this.enabled = obj.enabled,
                this.webhookUrl = obj.webhookUrl;
                this.httpMethod = obj.httpMethod;
                this.authHeader = obj.authHeader;
            }
            else {
                this.enabled = false;
                this.webhookUrl = null;
                this.httpMethod = this.httpOptions[0];
                this.authHeader = null;
            }
        },
        async save() {
            const automation = this.getData();

            try {
                await this.update({automation});
            }
            catch (e) {
                const data = getResponseData(e);
                if (data && data.message) {
                    this.errorMessage = data.message;
                }
                return;
            }
            this.$emit("saved");
        },
        isUrlValidOrEmpty(text) {
            return text ? text.length == 0 || (validateUrl(text) ? null : false) : null;
        }
    },
    watch: {
        automation(obj) {
            this.setData(obj);
        },
        edit() {
            this.setData(this.automation);
            this.errorMessage = null;
        }
    },
    created() {
        this.setData(this.automation);
    }
}
</script>

<style scoped>
    .label-col {
        min-width: 152px;
    }
</style>