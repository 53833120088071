<template>
    <CCard class="pb-2"> 
         <CCardHeader class="pt-2 pb-0">  
            <div class="float-left d-flex flex-row mt-1 mb-1" style="width:auto">
                <CIcon name="cil-bank" size="lg" class="mr-2" /><h5 class="">General Settings</h5>    
                <CSpinner v-if='loading && !editing' class="float-left ml-2 mt-1" size="sm" color="primary" /> 
            </div>           
        </CCardHeader>
        <CCardBody class="pt-2 pb-2">
            <template v-if="!loading || settings">
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Brand Logo</label>        
                <div class="ml-2">
                    <img :src="logoFile" class="logo" />
                </div>
            </div>     
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Company Name</label>        
                <span  class="col-form-label col text-info" v-if="settings">
                    {{ settings.companyName }}
                </span>
            </div>     
             <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Support E-Mail</label>        
                <span  class="col-form-label col text-info" v-if="settings">
                    {{ settings.contactEmail }}
                </span>
            </div>    
            </template>
        </CCardBody>
        <template v-if="!loading || automation || editAutomation">           
        <CCardHeader class="pt-2 pb-2 top-border"  style="background: #f8f8ff">     
            <EditCombo v-if="canEdit"
                class="float-right"
                @edit="editAutomation = true"
                @cancel="editAutomation = false"
                @save="onSaveAutomation()"
                :edit="editAutomation" 
                :disabled="loading"
            />            
            <h6 class="m-0 mt-1 mb-1 float-left">Automation</h6>                
            <CSpinner v-if='loading && editAutomation' class=" ml-2 mt-1" size="sm" color="primary" />         
        </CCardHeader> 
        <CCardBody class="pb-2 pt-2">
            <AutomationEditor ref="automationEditor" 
                :edit="editAutomation" 
                :automation="automation" 
                :disabled="loading"
                @saved="onSavedAutomation" 
            />
        </CCardBody>
        </template>
    </CCard>
</template>
<script>
import EditCombo from '@/components/EditCombo.vue';
import AutomationEditor from '@/components/editors/Automation.vue';
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/general/actions';
import { EDIT_SETTINGS, mapPermissions } from '@/permissions';

export default {
    components: {
        EditCombo,
        AutomationEditor
    },
    data() {
        return {
            editAutomation: false,
        }
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_SETTINGS
        }),
        ...mapGetters({
            logoFile: 'logoFile',
            loading: 'general/loading',
            settings: 'general/settings',
            automation: 'general/automation'
        }),
        editing() {
            const editing = this.editAutomation;
            return editing;
        }
    },
    methods: {
        ...mapActions({
            load: actionNames.LOAD,
            update: actionNames.UPDATE,
        }),
        async onSaveAutomation() {
            await this.$refs.automationEditor.save();
        },
        onSavedAutomation() {
            this.editAutomation = false;
        }
    },
    created() {
        this.load();
    }
}
</script>

<style scoped>
    .label-col {
        min-width: 152px;
    }
    img.logo {
        width:72px; 
        height:auto; 
        max-height: 30px; 
        margin-top: 3px;
        object-fit: contain; 
        object-position: left center;
    }    
</style>
<style>
.select-http-method {
    display: inline-block;
    max-width: 100px; 
}
</style>